<template>
  <div class="view view--company view--company-sponzoring">
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="img img--sponzoring img--sponzoring--l">
              <img src="/img/views/img-sponzoring-coinfy.png" alt="security" />
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="wrapper wrapper--v-center">
              <h1 class="tit fw-500 fc-dark ts-36 ts-md-48 mb-4" v-html="cc.s1.tit[1]" />
              <p class="txt fw-400 fc-gray mb-3" v-html="cc.s1.txt" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section--snow">
      <div class="container">
        <div class="row">
          <div class="d-flex flex-column-reverse flex-lg-row">
            <div class="col-12 col-lg-6">
              <div class="wrapper wrapper--v-center">
                <h1 class="tit fw-500 fc-dark ts-36 ts-md-48 mb-4" v-html="cc.s2.tit" />
                <p class="txt fw-400 fc-gray mb-3" v-html="cc.s2.txt" />
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="img img--sponzoring">
                <img src="/img/views/img-sponzoring-sparta.png" alt="security" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="d-flex flex-column-reverse flex-lg-row">
            <div class="col-12 col-lg-6">
              <div class="img img--sponzoring img--sponzoring--l">
                <img src="/img/views/img-sponzoring-enge.png" alt="security" />
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="wrapper wrapper--v-center">
                <h1 class="tit fw-500 fc-dark ts-36 ts-md-48 mb-4" v-html="cc.s3.tit" />
                <p class="txt fw-400 fc-gray mb-3" v-html="cc.s3.txt" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6">
              <h1 class="tit fw-500 fc-dark ts-36 ts-md-48 mb-4" v-html="cc.s4.tit" />
              <p class="txt fw-400 fc-gray mb-3" v-html="cc.s4.txt" />
          </div>
          <div class="col-12 col-lg-6">
            <div class="img img--sponzoring">
              <img src="/img/views/img-sponzoring-polivka.png" alt="security" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section--snow">
      <div class="container">
        <div class="row">
          <div class="d-flex flex-column-reverse flex-lg-row">
            <div class="col-12 col-lg-6">
              <div class="img img--sponzoring img--sponzoring--l">
                <img src="/img/views/img-sponzoring-dzaba.png" alt="security" />
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="wrapper wrapper--v-center">
                <h1 class="tit fw-500 fc-dark ts-36 ts-md-48 mb-4" v-html="cc.s5.tit" />
                <p class="txt fw-400 fc-gray mb-3" v-html="cc.s5.txt" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  computed: {
    cc () {
      return this.$t('views.company.sponzoring')
    },
    alleviate () {
      return this.$t('components.alleviate')
    }
  },
  metaInfo () {
    const meta = this.$t('meta.company.sponzoring')
    return {
      titleTemplate: '%s' + (meta.title ? ' | ' + meta.title : ''),
      meta: [
        { name: 'description', content: meta.description },
        { name: 'keywords', content: meta.keywords }
      ]
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>
